<template>
    <div>
        <!-- 头部框 -->
            <div class="box-card-tip">
                <div>
                    <i class="iconfont iconicon-13" ></i>

                    <span>您本次共上传发票<strong> {{ totalCount }} </strong>张，取消上传<strong> {{ deleteCount }} </strong>张，剩余可上传<strong> {{ importCount }} </strong>张</span>
                </div>
                <el-button type="primary" class="download" @click="batchDownLoad" :loading='isdeling' >确认上传</el-button>
            </div>
        <!-- 内容区域 -->
        <div class="contain">
            <!-- 确认区域 -->
            <div class="compNav">
                <div class="compNavIcon"></div>
                <p class="font_18">本次确认上传的文件</p>
            </div>
            <div class="itemConfirm" v-for="item in needUploadInvoices" :key="item.id">
                <!-- 预览item -->
                <p class="privewItem" @click="privewHandle(item.fileUrl,item.invoiceTime,item.invoiceCode,item.invoiceNo,item.invoiceYear,item.invoiceMonth,item.invoiceDate)">{{item.originalFileName}}</p>
                <i class="el-icon-delete" @click="delConfirm(item.id)"></i>
            </div>
            <!-- 间隔 -->
            <div class="interval"></div>
            <!-- 取消区域 -->
            <div class="compNav">
                <div class="compNavIcon"></div>
                <p class="font_18">本次取消上传的文件</p>
            </div>
            <div class="itemCancel" v-for="item in deletedInvoices" :key="item.id">
                <p><s>{{item.originalFileName}}</s></p>
                <!-- <i class="el-icon-delete"></i> -->
            </div>
        </div>
        <!-- 引入组件 -->
        <preview-dialog  ref="pdf" :width="pdfDialogWidth" ></preview-dialog>
    </div>
</template>

<script>
import axios from "axios";
import previewDialog from '@/components/previewDialog'
export default {
    name: 'WebIndex',
    components:{
        previewDialog
    },
    data() {
        return {
            queryForm: {
                invoiceAmount:null,
            },
        tableData: [{
            date: '2016-05-03',
            name: '王小虎',
            province: '上海',
            city: '普陀区',
            address: '上海市普陀区金沙江路 1518 弄',
            zip: 200333
        }],
        fileListConfirm:[],
        fileListCancel:[],
        visible: false,
        totalCount:'' ,//总数
        deleteCount:'' , //取消上传的数
        importCount:'' , //剩余可上传的数
        deletedInvoices:'' , // 已删除的文件列表
        needUploadInvoices:'' , // 需要校验的文件列表
        isdeling:false,
        pdfDialogWidth:"50%"
        };
    },
    mounted() {
        //监听父级嵌套页 传递消息
        window.addEventListener('message', this.updateMessage);
        this.getInfoList()
    },
    methods: {
        getInfoList(){
            this.$store.dispatch('GetInvoiceId',{
                clientId:this.getToken('preUploadClientId')
            }).then(res => {
            if(res.success){
                this.totalCount =res.data.totalCount //总数
                this.deleteCount =res.data.deleteCount //已删除大数
                this.importCount =res.data.totalCount - res.data.deleteCount
                this.deletedInvoices =res.data.deletedInvoices // 已删除的文件列表
                this.needUploadInvoices =res.data.needUploadInvoices // 需要校验的文件列表
            }else {
                res ? einvAlert.error("提示",res.msg||res.message) : einvAlert.error("提示",'未知异常，稍后再试')
            }
            }).catch(err =>{
                err ? einvAlert.error("提示",err.msg||err.message||err) : einvAlert.error("提示",'未知异常，稍后再试')
            })
        },
        // 使用
        updateMessage(e){ //监听父页面的方法 进行回调
          if(e.data.data != undefined && e.data.data != ''){
            this.$store.dispatch('LogOut').then((res) => {
                location.reload() // 为了重新实例化vue-router对象 避免bug
            })
          }
        },
        routerGo(){
          let  _this =this;
          console.log(_this.$route.query.requestFrom);
          var userId  = _this.getToken("userId");
          var refreshTime = _this.getToken("refreshTime"+userId);
          _this.removeItemToken("refreshTime"+userId);
          _this.$router.go(-refreshTime);
          // if("acc"==this.$route.query.requestFrom){
          //   _this.$router.go(-1);
          // }else{
          //   let path = this.$route.path.split('/')
          //   path.pop()
          //   let routerPath = path.join('/')
          //   this.$router.push(routerPath)
          // }

        //   setTimeout(() => {
        //     this.$router.push(routerPath +'/invoiceInfo')
        //   }, 100);

        //   console.log(routerPath,'path')
        //   console.log(routerPath +'/invoiceInfo','path2')
        },
        batchDownLoad(data){
            this.isdeling = true
            this.$store.dispatch('PostUpload',{
              clientId:this.getToken('preUploadClientId')
            }).then(res =>{
              // 以文件传输格式 file: (binary) 进行上传
              // employImport(formData).then(res => { // 以文件传输格式 file: (binary) 进行上传
              let  _this=this;
              if (res.success && res.data.isSuccess) {
                einvAlert.success("导入成功","导入成功"+res.data.successCount+"张");
                if(this.getToken('uploadType')){
                    setTimeout(function (){
                        //定义代理记账 上传类型 --> 纸票确定 iframe通讯
                        window.parent.postMessage("导入成功", '*');
                    },500);
                }
                setTimeout(function (){
                //   _this.$router.push('/income/invoiceAccount')
                // _this.$router.go(-1)
                  _this.routerGo()
                },500);
              }else if(res.success){

                einvAlert.confirm(function (){
                      _this.uploadError(res.data.logId)
                    },"<span>导入成功"+res.data.successCount+"张</span>,"+"<span style='color: red'>导入失败"+res.data.failCount+"张</span>",
                    '下载错误记录','取消',function (){
                      _this.clickMark();
                    },function (){
                      _this.clickMark();
                    });
              }else{
                einvAlert.error("错误",res.msg);
                window.parent.postMessage("导入失败", '*');
              }
              this.isdeling =false
            }).catch(err => {
              this.isdeling =false
            })
            //当前为iframe页面 发送消息
        },
      clickMark(){
         let _this =this;
        // _this.$router.go(-1)
        this.routerGo()
      },
      uploadError(val){
        let _this =this;
        this.isdeling = true
        axios({
          method: 'get',
          url: '/api/euser/log/downloadAttachment',
          responseType: 'blob',
          params: {
            logId : val
          },
          headers: {
            'Content-Type': "application/json;charset=utf-8",
            'Authorization': this.getCookies('Authorization'),
            'group':process.env.VUE_APP_GROUP
          }
        }).then((res) => {
          let data = res.data
          let url = window.URL.createObjectURL(new Blob([data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" }))
          let link = document.createElement('a')
          link.style.display = 'none'
          link.href = url;
          var now = window.formatDate(new Date(), 'yyyyMMddhhmmss')
          link.setAttribute('download', '发票导入失败数据' + now + '.xls')
          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link)
          this.isdeling = false
          _this.clickMark();
        }).catch(err=>{
          this.isdeling = false
          err ? einvAlert.error("提示",err.msg||err.message||err) : einvAlert.error("提示",'未知异常，稍后再试')
        })
      },
        //页面预览
        privewHandle(fileUrl,invoiceTime,invoiceCode,invoiceNo,invoiceYear,invoiceMonth,invoiceDate){
            var item ={
                'fileUrl':fileUrl,
                'invoiceTime':invoiceTime,
                'invoiceYear':invoiceYear,
                'invoiceMonth':invoiceMonth,
                'invoiceDate':invoiceDate,
                'invoiceCode':invoiceCode,
                'invoiceNo':invoiceNo
            }
            // this.$refs.pdf.getFileUrl2(item)
            this.$refs.pdf.getFileUrl2(item,false)
        },
        cancelTask(){
          /* 调清除上次数据接口 */
          this.$store.dispatch('PostCancelUpload',{
            clientId:this.getToken('preUploadClientId')
          }).then(res =>{

          }).catch(err =>{

          })
          this.importVis = true;
        },
        delConfirm(id){
            let _this=this;
            if(_this.needUploadInvoices.length==1){
              einvAlert.confirm(function (){
                if(_this.getToken('uploadType')){
                    setTimeout(function (){
                        //定义代理记账 上传类型 --> 纸票删除 iframe通讯
                        window.parent.postMessage("导入成功", '*');
                    },500);
                }else{
                    _this.cancelTask();
                    // _this.$router.go(-1);
                    _this.routerGo()
                }
              },"删除最后一张发票后，本次导入任务 默认取消，请确认是否继续删除？","确认","取消")

            }else{
              _this.delHandle(id)
            }
        },
        delHandle(id){
            this.$store.dispatch('PostDeleteImage',{
                clientId:this.getToken('preUploadClientId'),
                invoiceId:id
            }).then(res => {
                if(res.success){
                    this.getInfoList()
                }

            }).catch(err =>{
                err ? einvAlert.error("提示",err.msg||err.message||err) : einvAlert.error("提示",'未知异常，稍后再试')
            })
        },
        //会话框取消按钮
        pdfCancel(){
            this.visible = false
        }
    },
    computed:{
    }
};
</script>

<style lang="less" scoped>
.box-card-tip {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 60px;
    padding: 22px;
    margin-bottom: 22px;
    font-size: 16px;
    font-family: Microsoft YaHei, Microsoft YaHei-Regular;
    font-weight: 400;
    text-align: left;
    color: #333333;
    background-color: #fff;
    .iconicon-13 {
        color: rgba(255, 151, 0);
        padding-right: 6px;
    }
    .download {
    width: 100px;
    height: 40px;
    padding: 0;
    font-size: 16px;
    font-family: Microsoft YaHei, Microsoft YaHei-Regular;
    font-weight: 400;
    line-height: 40px;
    text-align: center;
    }
    strong {
        color: #409eff;
        font-size:18px ;
    }
}
.contain{
    // width: 1564px;
    width: 100%;
    height: 1370px;
    background-color: #fff;
    padding:20px;
    .compNav {
        display: flex;
        justify-content: left;
        align-items: center;
        width: 100%;
        padding-bottom: 10px;
        border-bottom: 1px solid #d1d1d1;
        margin-bottom: 24px;
        font-size: 16px;
        .compNavIcon {
            width: 4px;
            height: 16px;
            margin-right: 12px;
            background-color: #409eff;

            border-radius: 50em;
        }
    }
    .font_18 {
        font-size: 18px;
    }
    .interval {
        float: left;
        width: 100%;
        height: 24px;
    }
}
.itemConfirm, .itemCancel{
    float: left;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 380px;
    height: 45px;
    padding: 10px;
    font-size: 16px;
    line-height: 30px;
    font-family: Microsoft YaHei, Microsoft YaHei-Regular;
    font-weight: 400;
    text-align: left;
    color: #333333;
    p {
        margin: 0;
        s {
            color: #666;
        }
    }
    .el-icon-delete {
        padding-right:100px;
        opacity: 0;
        cursor:pointer;
    }
}

.itemConfirm:hover {
    color: #409eff;
    text-decoration: underline;
    cursor: pointer;
    i {
        opacity: 1;
    }
}
.el-col-12 {
    margin: 0;
}

/deep/.form-inline {
    .double_query {
        .el-form-item__content {
        width: 70% !important;
        margin-right:0 !important;
        }
    }
    .single_query {
        .el-form-item__content {
        width: 84% !important;
        margin-right:0 !important;
        }
    }
}
.compNavT {
    padding: 24px 0;
    font-size: 16px;
}
.invoice-Template {
    position:sticky;
    top: 0;
}

</style>
